import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgbDateAdapter, NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LoginComponent} from './login/login/login.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {AuthService} from './services/auth.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ClickOutsideModule} from 'ng-click-outside';
import {UsersComponent} from './users/users.component';
import {GroupsComponent} from './groups/groups.component';
import {GroupEditModalComponent} from './group-edit-modal/group-edit-modal.component';
import {UserEditModalComponent} from './user-edit-modal/user-edit-modal.component';
import {BranchesComponent} from './branches/branches.component';
import {BranchEditModalComponent} from './branch-edit-modal/branch-edit-modal.component';
import {TokenService} from './services/token.service';
import {UsersService} from './services/users.service';
import {ProductsComponent} from './products/products.component';
import {CreditorsComponent} from './creditors/creditors.component';
import {CreditorEditModalComponent} from './creditor-edit-modal/creditor-edit-modal.component';
import {DebtorsComponent} from './debtors/debtors.component';
import {DebtorEditModalComponent} from './debtor-edit-modal/debtor-edit-modal.component';
import {PriceGroupsComponent} from './price-groups/price-groups.component';
import {PriceGroupEditModalComponent} from './price-group-edit-modal/price-group-edit-modal.component';
import {OrdersComponent} from './orders/orders.component';
import {DebtorSelectModalComponent} from './debtor-select-modal/debtor-select-modal.component';
import {OrderEditComponent} from './order-edit/order-edit.component';
import {OrderStatesComponent} from './order-states/order-states.component';
import {OrderStateEditModalComponent} from './order-state-edit-modal/order-state-edit-modal.component';
import {ReferencesComponent} from './references/references.component';
import {ReferenceEditModalComponent} from './reference-edit-modal/reference-edit-modal.component';
import {ProductCategoriesComponent} from './product-categories/product-categories.component';
import {ProductCategoryEditModalComponent} from './product-category-edit-modal/product-category-edit-modal.component';
import {ProductCategoriesTreeComponent} from './product-categories-tree/product-categories-tree.component';
import {OrderLineEditModalComponent} from './order-line-edit-modal/order-line-edit-modal.component';
import {ProductEditComponent} from './product-edit/product-edit.component';
import {ProductCategorySelectModalComponent} from './product-category-select-modal/product-category-select-modal.component';
import {ProductFieldsComponent} from './product-fields/product-fields.component';
import {ProductFieldEditModalComponent} from './product-field-edit-modal/product-field-edit-modal.component';
import {ProductFieldPresetsComponent} from './product-field-presets/product-field-presets.component';
import {ProductFieldPresetEditModalComponent} from './product-field-preset-edit-modal/product-field-preset-edit-modal.component';
import {SortablejsModule} from 'ngx-sortablejs';
import {ProductPriceAddModalComponent} from './price-add-modal/product-price-add-modal.component';
import {PriceAdjustmentsComponent} from './price-adjustments/price-adjustments.component';
import {CustomAdapter, CustomDateParserFormatter, NgbdDatepickerAdapter} from './custom-date-picker/custom-date-picker.component';
import {PriceAdjustmentEditComponent} from './price-adjustment-edit/price-adjustment-edit.component';
import {ProductSelectModalComponent} from './product-select-modal/product-select-modal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {ProductStockAddModalComponent} from './product-stock-add-modal/product-stock-add-modal.component';
import {CreditorSelectModalComponent} from './creditor-select-modal/creditor-select-modal.component';
import {AuditLogsComponent} from './audit-logs/audit-logs.component';
import {ThemeDarkComponent} from './theme-dark/theme.component';
import {ThemeLightComponent} from './theme-light/theme.component';
import {VatTypesComponent} from './vat-types/vat-types.component';
import {VatTypeEditModalComponent} from './vat-type-edit-modal/vat-type-edit-modal.component';
import { LoaderComponent } from './widgets/loader/loader.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { HeaderComponent } from './globals/header/header.component';
import { NavigationComponent } from './globals/navigation/navigation.component';
import { TableComponent } from './widgets/table/table.component';
import { TableHelperComponent } from './widgets/table-helper/table-helper.component';
import {DatesComponent} from './dates/dates.component';
import {DateEditModalComponent} from './date-edit-modal/date-edit-modal.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import {UserComponent} from './user/user.component';
import {User2FAModalComponent} from './user-2fa-modal/user-2fa-modal.component';
import {SafeEmbeddedImagePipe} from './pipes/safe-embedded-image-pipe';
import {OrderPrintModalComponent} from './order-print-modal/order-print-modal.component';
import {PaymentAgreementEditModalComponent} from './payment-agreement-edit-modal/payment-agreement-edit-modal.component';
import {PaymentAgreementsComponent} from './payment-agreements/payment-agreements.component';
import {ProductImportComponent} from './product-import/product-import.component';
import {ContactFieldsComponent} from './contact-fields/contact-fields.component';
import {ContactFieldEditModalComponent} from './contact-field-edit-modal/contact-field-edit-modal.component';
import {ContactFieldPresetsComponent} from './contact-field-presets/contact-field-presets.component';
import {ContactFieldPresetEditModalComponent} from './contact-field-preset-edit-modal/contact-field-preset-edit-modal.component';
import {ContactEditSelector} from './contact-edit-selector/contact-edit-selector.component';
import {ContactsEditor} from './contacts-editor/contacts-editor.component';
import { OfferGeneratorComponent } from './offer-generator/offer-generator.component';
import { SettingsComponent } from './settings/settings.component';
import { InlineNavigationComponent } from './globals/inline-navigation/inline-navigation.component';
import { ToolOrdersComponent } from './offer-generator/tool-orders/tool-orders.component';
import { StepStonesComponent } from './widgets/step-stones/step-stones.component';
import { ReadyOrdersComponent } from './offer-generator/ready-orders/ready-orders.component';
import { CustomOrderComponent } from './offer-generator/custom-order/custom-order.component';
import { ConfirmOrderComponent } from './offer-generator/confirm-order/confirm-order.component';
import { WheelSelectComponent } from './offer-generator/wheel-select/wheel-select.component';
import { ToolSelectComponent } from './offer-generator/tool-select/tool-select.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import {CreditorComponent} from './creditor/creditor.component';
import {DebtorComponent} from './debtor/debtor.component';
import {CreditorsUserEditModalComponent} from './creditors-user-edit-modal/creditors-user-edit-modal.component';
import {DebtorsUserEditModalComponent} from './debtors-user-edit-modal/debtors-user-edit-modal.component';
import { ContactEditComponent } from './contact-edit/contact-edit.component';
import {SortByPipe} from './pipes/sort-by-pipe';
import {UnitsComponent} from './units/units.component';
import {OrderTypesComponent} from './order-types/order-types.component';
import {OrderTypeEditModalComponent} from './order-type-edit-modal/order-type-edit-modal.component';
import {OrderLineAjourModalComponent} from './order-line-ajour-modal/order-line-ajour-modal.component';
import {ChartsModule} from 'ng2-charts';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {ArticlesComponent} from './articles/articles.component';
import {ArticleEditComponent} from './article-edit/article-edit.component';
import {QuillModule} from 'ngx-quill';
import {ClipboardModule} from 'ngx-clipboard';
import { QuillEditorComponent } from './quill-editor/quill-editor.component';
import { LanguagePickerComponent } from './language-picker/language-picker.component';
import { LanguageIndicatorComponent } from './language-indicator/language-indicator.component';
import { CurrenciesComponent } from './currencies/currencies.component';
import { CurrencyEditModalComponent } from './currency-edit-modal/currency-edit-modal.component';
import { ProductEditorComponent } from './product-editor/product-editor.component';
import { ProductChildRelationsEditComponent } from './product-child-relations-edit/product-child-relations-edit.component';
import { ProductParentRelationsEditComponent } from './product-parent-relations-edit/product-parent-relations-edit.component';

/* Thorsens Imports */
import { ThorsensOrdersComponent } from './themes/thorsens/orders/orders.component';
import { ThorsensEditOrderComponent } from './themes/thorsens/edit-order/edit-order.component';
import { OrderDeliveriesModalComponent } from './themes/thorsens/order-deliveries-modal/order-deliveries-modal.component';
import { DeliveriesComponent } from './themes/thorsens/deliveries/deliveries.component';
import { ThorsensDeliveryDetailsComponent } from './themes/thorsens/thorsens-delivery-details/thorsens-delivery-details.component';
import { FieldsComponent } from './themes/thorsens/fields/fields.component';
import { AnalysisDetailComponent } from './themes/thorsens/analysis-detail/analysis-detail.component';
import { ThorsensDeliveryDetailsModalComponent } from './themes/thorsens/thorsens-delivery-details-modal/thorsens-delivery-details-modal.component';
import {ThorsensDashboardComponent} from './themes/thorsens/thorsens-dashboard/thorsens-dashboard.component';

/* 10-4 Imports */
import { TenFourStep1OrderDetailsComponent } from './themes/10-4/step-1-order-details/step-1-order-details.component';
import { LayoutSplitterComponent } from './widgets/layout-splitter/layout-splitter.component';
import { TenFourOrderComponent } from './themes/10-4/order/order.component';
import { TenFourStep2OrderOverviewComponent } from './themes/10-4/step-2-order-overview/step-2-order-overview.component';
import { ClientContactInfoComponent } from './themes/10-4/client-contact-info/client-contact-info.component';
import { DatesAndInfoComponent } from './themes/10-4/dates-and-info/dates-and-info.component';
import { ClientContactMetaComponent } from './themes/10-4/client-contact-meta/client-contact-meta.component';
import { TotalPricesComponent } from './themes/10-4/total-prices/total-prices.component';
import { ChangeOrderClientComponent } from './themes/10-4/change-order-client/change-order-client.component';
import { ChangeOrderDeliveryContactComponent } from './themes/10-4/change-order-delivery-contact/change-order-delivery-contact.component';
import { InfoAndDeliveryAddressInputsComponent } from './themes/10-4/info-and-delivery-address-inputs/info-and-delivery-address-inputs.component';
import { UnitEditModalComponent } from './unit-edit-modal/unit-edit-modal.component';
import { TenFourOrdersComponent } from './themes/10-4/orders/orders.component';

/* Helm's Imports */
import { HelmsOrdersComponent } from './themes/helms/helms-orders/helms-orders.component';
import {ProductQuantityDiscountModalComponent} from './product-quantity-discount-modal/product-quantity-discount-modal.component';
import { ProductsSearchAndAddComponent } from './themes/10-4/products-search-and-add/products-search-and-add.component';
import { OrderlinesComponent } from './themes/10-4/orderlines/orderlines.component';
import { CreateOrderComponent } from './themes/10-4/create-order/create-order.component';
import { SelectNewOrderStateComponent } from './themes/10-4/select-new-order-state/select-new-order-state.component';
import { SelectNewOrderClientComponent } from './themes/10-4/select-new-order-client/select-new-order-client.component';
import { NoSearchInputOrResultsComponent } from './widgets/no-search-input-or-results/no-search-input-or-results.component';
import { TableSortingButtonComponent } from './widgets/table-sorting-button/table-sorting-button.component';
import { CardSelectComponent } from './widgets/card-select/card-select.component';
import { SelectNewOrderTypeComponent } from './themes/10-4/select-new-order-type/select-new-order-type.component';
import {PriceGroupSelectModalComponent} from './price-group-select-modal/price-group-select-modal.component';
import {ContactTypesComponent} from './contact-types/contact-types.component';
import {ContactTypeEditModalComponent} from './contact-type-edit-modal/contact-type-edit-modal.component';
import { HelmsDashboardComponent } from './themes/helms/helms-dashboard/helms-dashboard.component';
import {DynamicTranslatePipe} from './pipes/dynamic-translate-pipe';
import { HelmsInventoryComponent } from './themes/helms/helms-inventory/helms-inventory.component';
import { SwitchComponent } from './widgets/switch/switch.component';
import { HelmsFilecenterComponent } from './themes/helms/helms-filecenter/helms-filecenter.component';
import { HelmsStatisticsComponent } from './themes/helms/helms-statistics/helms-statistics.component';
import { HelmsScoreboardComponent } from './themes/helms/helms-scoreboard/helms-scoreboard.component';
import { HelmsCompareComponent } from './themes/helms/helms-compare/helms-compare.component';
import {AttachmentsComponent} from './attachments/attachments.component';
import {AttachmentUploadModalComponent} from './attachment-upload-modal/attachment-upload-modal.component';
import { HelmsDemosComponent } from './themes/helms/helms-demos/helms-demos.component';
import { MissingDataComponent } from './widgets/missing-data/missing-data.component';
import {OrderLineFieldEditModalComponent} from './order-line-field-edit-modal/order-line-field-edit-modal.component';
import {OrderLineFieldsComponent} from './order-line-fields/order-line-fields.component';
import {HelmsProductionLinesModule} from './helms-production-lines/helms-production-lines.module';
import { HelmsToolsOrderComponent } from './themes/helms/helms-tools-order/helms-tools-order.component';
import {environment} from '../environments/environment';
import {OfferGeneratorService} from './services/offer-generator.service';
import { HelmsPrintFriendlyOrderComponent } from './themes/helms/helms-print-friendly-order/helms-print-friendly-order.component';
import {SaleComponent} from './themes/10-4/sale/sale.component';
import {BuyComponent} from './themes/10-4/buy/buy.component';
import {LookupComponent} from './themes/10-4/lookup/lookup.component';
import {InventoryComponent} from './themes/10-4/inventory/inventory.component';
import {PrintComponent} from './themes/10-4/print/print.component';
import {FinanceComponent} from './themes/10-4/finance/finance.component';
import {CorporateComponent} from './themes/10-4/corporate/corporate.component';
import {ProductionLineEditModalComponent} from './helms-production-lines/production-line-edit-modal/production-line-edit-modal.component';
import { ProductRelationGroupEditModalComponent } from './product-relation-group-edit-modal/product-relation-group-edit-modal.component';
import {UserSelectModalComponent} from './user-select-modal/user-select-modal.component';
import { ProductRelationEditModalComponent } from './product-relation-edit-modal/product-relation-edit-modal.component';
import {HelmsOrderEditComponent} from './themes/helms/helms-order-edit/helms-order-edit.component';
import {CheckboxConfirmModalComponent} from './checkbox-confirm-modal/checkbox-confirm-modal.component';
import {FilterPipe} from './pipes/filter-pipe';

import * as dayjs from 'dayjs';
import { LoadOfferComponent } from './offer-generator/load-offer/load-offer.component';
import {HelmsOrdersArrivalsComponent} from './themes/helms/helms-orders-arrivals/helms-orders.component';
import {HelmsOrdersFactoryComponent} from './themes/helms/helms-orders-factory/helms-orders-factory.component';
import {HelmsOffersComponent} from './themes/helms/helms-offers/helms-offers.component';
import {ProductRelationFilterPipe} from './pipes/product-relation-filter-pipe';
import {PriceFilterPipe} from './pipes/price-filter-pipe';
import { AttachmentDownloadComponent } from './attachment-download/attachment-download.component';
import {SafeHtmlPipe} from './pipes/safe-html-pipe';
import {OrderFieldEditModalComponent} from './order-field-edit-modal/order-field-edit-modal.component';
import {OrderFieldsComponent} from './order-fields/order-fields.component';
import {OrdersService} from "./services/orders.service";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";

// tslint:disable-next-line:typedef
dayjs.prototype.toJSON = function() {
    return this.format('YYYY-MM-DDTHH:mm:ssZ');
};

let extraServices = [];
if (environment.customer === 'helms') {
    // @todo move Helms offer services and components into Helms feature module instead
     extraServices = [OfferGeneratorService, OrdersService];
} else {
     extraServices = [];
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        UserComponent,
        UsersComponent,
        UserEditModalComponent,
        User2FAModalComponent,
        GroupsComponent,
        GroupEditModalComponent,
        BranchesComponent,
        BranchEditModalComponent,
        AuditLogsComponent,
        AttachmentsComponent,
        AttachmentUploadModalComponent,
        ProductsComponent,
        ProductEditComponent,
        ProductSelectModalComponent,
        ProductStockAddModalComponent,
        ProductCategoriesComponent,
        ProductCategoryEditModalComponent,
        ProductCategoriesTreeComponent,
        ProductCategorySelectModalComponent,
        ProductFieldsComponent,
        ProductFieldEditModalComponent,
        ProductFieldPresetsComponent,
        ProductFieldPresetEditModalComponent,
        ProductPriceAddModalComponent,
        UserSelectModalComponent,
        CreditorsComponent,
        CreditorComponent,
        CreditorEditModalComponent,
        CreditorSelectModalComponent,
        CreditorsUserEditModalComponent,
        DebtorsComponent,
        DebtorComponent,
        CheckboxConfirmModalComponent,
        DebtorEditModalComponent,
        DebtorsUserEditModalComponent,
        DebtorSelectModalComponent,
        PriceGroupsComponent,
        PriceGroupEditModalComponent,
        PriceGroupSelectModalComponent,
        OrdersComponent,
        OrderEditComponent,
        OrderPrintModalComponent,
        OrderLineEditModalComponent,
        OrderStatesComponent,
        OrderStateEditModalComponent,
        OrderTypesComponent,
        OrderTypeEditModalComponent,
        OrderLineFieldEditModalComponent,
        OrderLineFieldsComponent,
        OrderFieldEditModalComponent,
        OrderFieldsComponent,
        ReferencesComponent,
        ReferenceEditModalComponent,
        DatesComponent,
        DateEditModalComponent,
        PriceAdjustmentsComponent,
        PriceAdjustmentEditComponent,
        NgbdDatepickerAdapter,
        DashboardComponent,
        VatTypesComponent,
        VatTypeEditModalComponent,
        UnitsComponent,
        UnitEditModalComponent,
        ThemeDarkComponent,
        ThemeLightComponent,
        DashboardComponent,
        LoaderComponent,
        HeaderComponent,
        NavigationComponent,
        TableComponent,
        TableHelperComponent,
        ForgotPasswordComponent,
        SafeEmbeddedImagePipe,
        SortByPipe,
        FilterPipe,
        PriceFilterPipe,
        SafeHtmlPipe,
        ProductRelationFilterPipe,
        DynamicTranslatePipe,
        PaymentAgreementEditModalComponent,
        PaymentAgreementsComponent,
        ProductImportComponent,
        ContactFieldsComponent,
        ContactFieldEditModalComponent,
        ContactFieldPresetsComponent,
        ContactFieldPresetEditModalComponent,
        ContactTypesComponent,
        ContactTypeEditModalComponent,
        ContactEditSelector,
        ContactsEditor,
        OfferGeneratorComponent,
        SettingsComponent,
        InlineNavigationComponent,
        ToolOrdersComponent,
        StepStonesComponent,
        ReadyOrdersComponent,
        CustomOrderComponent,
        ConfirmOrderComponent,
        WheelSelectComponent,
        ToolSelectComponent,
        ConfirmOrderComponent,
        ResetPasswordComponent,
        ThorsensEditOrderComponent,
        ThorsensOrdersComponent,
        TenFourStep1OrderDetailsComponent,
        LayoutSplitterComponent,
        TenFourOrderComponent,
        TenFourStep2OrderOverviewComponent,
        OrderDeliveriesModalComponent,
        DeliveriesComponent,
        ThorsensDashboardComponent,
        ThorsensDeliveryDetailsComponent,
        FieldsComponent,
        AnalysisDetailComponent,
        ThorsensDeliveryDetailsModalComponent,
        ContactEditComponent,
        HelmsOrdersComponent,
        ProductQuantityDiscountModalComponent,
        ClientContactInfoComponent,
        DatesAndInfoComponent,
        ClientContactMetaComponent,
        TotalPricesComponent,
        ChangeOrderClientComponent,
        ChangeOrderDeliveryContactComponent,
        InfoAndDeliveryAddressInputsComponent,
        ProductsSearchAndAddComponent,
        OrderlinesComponent,
        CreateOrderComponent,
        SelectNewOrderStateComponent,
        SelectNewOrderClientComponent,
        TenFourOrdersComponent,
        OrderLineAjourModalComponent,
        NoSearchInputOrResultsComponent,
        TableSortingButtonComponent,
        CardSelectComponent,
        SelectNewOrderTypeComponent,
        HelmsDashboardComponent,
        HelmsInventoryComponent,
        SwitchComponent,
        HelmsFilecenterComponent,
        HelmsOrderEditComponent,
        HelmsStatisticsComponent,
        HelmsScoreboardComponent,
        HelmsCompareComponent,
        HelmsDemosComponent,
        MissingDataComponent,
        HelmsToolsOrderComponent,
        ArticlesComponent,
        ArticleEditComponent,
        SaleComponent,
        BuyComponent,
        LookupComponent,
        InventoryComponent,
        PrintComponent,
        FinanceComponent,
        CorporateComponent,
        ArticleEditComponent,
        HelmsPrintFriendlyOrderComponent,
        HelmsOrdersArrivalsComponent,
        HelmsOffersComponent,
        HelmsOrdersFactoryComponent,
        ProductionLineEditModalComponent,
        QuillEditorComponent,
        LanguagePickerComponent,
        LanguageIndicatorComponent,
        CurrenciesComponent,
        CurrencyEditModalComponent,
        ProductEditorComponent,
        ProductChildRelationsEditComponent,
        ProductParentRelationsEditComponent,
        ProductRelationGroupEditModalComponent,
        ProductRelationEditModalComponent,
        LoadOfferComponent,
        AttachmentDownloadComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        HttpClientModule,
        FormsModule,
        ClipboardModule,
        FontAwesomeModule,
        ClickOutsideModule,
        ChartsModule,
        InfiniteScrollModule,
        SortablejsModule.forRoot({ animation: 150 }),
        InlineSVGModule.forRoot(),
        QuillModule.forRoot(),
        HelmsProductionLinesModule,
        NgMultiSelectDropDownModule,
    ],
    providers: [
        AuthService,
        TokenService,
        UsersService,
        {provide: NgbDateAdapter, useClass: CustomAdapter},
        {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
            ...extraServices
    ],
    exports: [
        LoaderComponent,
        TableComponent,
        MissingDataComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
