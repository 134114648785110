import {Component, OnInit} from '@angular/core';
import {Contact} from "../services/contact";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ContactFieldPreset} from "../services/contact-field-preset";
import {ContactType} from "../services/contact-type";
import {ContactTypesService} from "../services/contact-types.service";
import {forkJoin} from "rxjs";
import {ContactFieldPresetsService} from "../services/contact-field-presets.service";

@Component({
    selector: 'app-contact-edit',
    templateUrl: './contact-edit.component.html',
    styleUrls: ['./contact-edit.component.scss'],
    providers: [ContactTypesService, ContactFieldPresetsService]
})
export class ContactEditComponent implements OnInit {

    public contact: Contact;
    public _contact: Contact;

    public contactTypes: ContactType[];
    public contactFieldPresets: ContactFieldPreset[];

    public preset: ContactFieldPreset;

    public saving = false;
    public saved;

    constructor(
        public contactFieldPresetsService: ContactFieldPresetsService,
        public activeModal: NgbActiveModal,
        public contactTypesService: ContactTypesService) {
    }

    ngOnInit(): void {

        forkJoin([
            this.contactTypesService.getContactTypes(),
            this.contactFieldPresetsService.getContactFieldPresets(true),
        ]).subscribe(joinData => {
            this.contactTypes = joinData[0].data;
            this.contactFieldPresets = joinData[1].data;
            if (this.contact) {
                this._contact = JSON.parse(JSON.stringify(this.contact));
                this.contactTypeChanged();
            } else {
                this._contact = Contact.fromJSON({
                    name: '',
                    nickname: '',
                });
            }
        });
    }

    close(): void {
        this.activeModal.dismiss();
    }

    save(): void {
        this.saving = true;
        this.activeModal.dismiss();
        this.saved(this._contact);
    }

    /**
     * @todo handle removing fields not present in preset
     */
    contactTypeChanged(): void {

        const typeData = this.contactTypes.find((e) => {
            return e.id === this._contact.contact_type_id;
        });
        if (!typeData) {
            return;
        }
        const preset = this.contactFieldPresets.find((e) => {
            return e.id === typeData.contact_field_preset_id;
        });
        if (preset) {
            this._contact.contact_fields = this._contact.contact_fields.filter((e) => {
                if (e._joinData.value && e._joinData.value !== '') {
                    return true;
                }
                return false;
            });

            for (const contactField of preset.contact_fields) {
                if (!this._contact.contact_fields.some(e => e.id === contactField.id)) {
                    contactField._joinData = {value: ''};
                    this._contact.contact_fields.push(contactField);
                }
            }
        }
    }
}
